import { Button, Space, Table, Popconfirm, Modal, Form, message, Spin } from 'antd'
// useMemo 优化
import { forwardRef, ReactNode, ReactElement, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import './index.scss'
import { ColumnsType } from 'antd/es/table'

export type CRUDTableInstance = {
  readonly refresh: () => void
}

export type CRUDTableParams = any

export type CRUDTableLoadFn<T> = (
  page: number,
  pageSize: number,
  params?: CRUDTableParams
) => Promise<{
  list: T[]
  total: number
}>

export type CRUDTableProps<T> = {
  title?: string
  pageSize?: number
  params?: CRUDTableParams
  columns?: ColumnsType<T>
  header?: ReactNode
  formValue?: Tool.Partial<T>
  formItems?: ReactNode
  canCreate?: boolean
  canRemove?: boolean
  canCopy?: boolean
  onLoad?: CRUDTableLoadFn<T> // 加载数据函数
  onRemove?: (item: T) => Promise<AnyObject> // 确认删除回调
  onSubmit?: (item: Tool.Partial<T>) => Promise<AnyObject>
  onBeforeEdit?: (item: Tool.Partial<T>) => Promise<Tool.Partial<T>>
  onError?: (err: any) => void
}

function CRUDTable<T extends AnyObject = any>(props: CRUDTableProps<T>, ref: Ref<CRUDTableInstance>) {
  const { columns = [], canCreate = true, canRemove = true, canCopy = false, pageSize = 10, params } = props
  const [fn] = useState<{
    load?: CRUDTableLoadFn<T>
  }>({
    load: props.onLoad
  })
  // 表格数据
  const [response, setResponse] = useState<T[]>([])
  // 分页变量
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  // 表单变量
  const [submiting, setSubmiting] = useState(false)
  const [modalMode, setModalMode] = useState(-1)
  const [formRef] = Form.useForm()

  const refresh = useCallback(
    (targetPage: number) => {
      console.log('easy table refresh')
      setLoading(true)
      fn.load &&
        fn
          .load(targetPage, pageSize, params)
          .then(res => {
            setLoading(false)
            setPage(targetPage)
            setResponse(res.list)
            if (targetPage === 1) {
              setTotal(res.total)
            }
          })
          .catch(() => {
            setPage(targetPage)
            setLoading(false)
            setResponse([])
            setTotal(0)
          })
    },
    [fn, pageSize, params]
  )

  useEffect(() => {
    refresh(1)
  }, [refresh])

  useImperativeHandle(
    ref,
    () => {
      return {
        refresh() {
          refresh(page)
        }
      }
    },
    [page, refresh]
  )

  return (
    <div className="crud-table">
      {props.header ? <div className="crud-table-header">{props.header}</div> : ''}
      <Table<T>
        dataSource={response}
        rowKey="id"
        loading={loading}
        pagination={{
          current: page,
          pageSize,
          showSizeChanger: false,
          total: total,
          onChange: p => {
            refresh(p)
          }
        }}
      >
        {columns.map((column, index) => {
          return (
            <Table.Column
              key={index}
              title={column.title}
              dataIndex={(column as any).dataIndex}
              render={column.render}
              width={column.width}
            ></Table.Column>
          )
        })}
        {props.formItems ? (
          <Table.Column
            title={
              canCreate ? (
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    setModalMode(0)
                    formRef.resetFields()
                    let value = props.formValue
                    if (props.formValue && props.onBeforeEdit) {
                      setSubmiting(true)
                      props.onBeforeEdit(props.formValue).then(res => {
                        setSubmiting(false)
                        formRef.setFieldsValue(res)
                      })
                    } else {
                      formRef.setFieldsValue(value)
                    }
                  }}
                >
                  新增
                </Button>
              ) : (
                <span></span>
              )
            }
            key="action"
            width={80}
            render={(_: any, record: T) => (
              <Space size="middle">
                <Button
                  type="primary"
                  ghost
                  onClick={() => {
                    formRef.resetFields()
                    setModalMode(1)
                    if (props.onBeforeEdit) {
                      setSubmiting(true)
                      props.onBeforeEdit(record).then(res => {
                        setSubmiting(false)
                        formRef.setFieldsValue(res)
                      })
                    } else {
                      formRef.setFieldsValue(record)
                    }
                  }}
                >
                  编辑
                </Button>
                {canRemove ? (
                  <Popconfirm
                    placement="topLeft"
                    title="是否删除"
                    description="删除后无法恢复，是否删除？"
                    onConfirm={() => {
                      props.onRemove &&
                        props.onRemove(record).then(() => {
                          refresh(page)
                        })
                    }}
                    okText="删除"
                    cancelText="取消"
                  >
                    <Button danger ghost>
                      删除
                    </Button>
                  </Popconfirm>
                ) : (
                  ''
                )}
                {canCopy ? (
                  <Button
                    onClick={() => {
                      setModalMode(0)
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { id, ..._value } = record
                      formRef.resetFields()
                      let value = {
                        ...props.formValue,
                        ..._value
                      }
                      formRef.setFieldsValue(value)
                    }}
                  >
                    复制
                  </Button>
                ) : (
                  ''
                )}
              </Space>
            )}
          />
        ) : (
          ''
        )}
      </Table>
      <Modal
        className="crud-table-modal"
        title={<div className="flex-center">{`${modalMode >= 1 ? '编辑' : '新增'}`}</div>}
        open={modalMode >= 0}
        width="70%"
        closable={!submiting}
        centered
        footer={null}
        onCancel={() => {
          setModalMode(-1)
        }}
      >
        <Spin spinning={submiting}>
          <Form
            labelCol={{ span: 3 }}
            labelWrap
            form={formRef}
            onFinishFailed={({ errorFields }) => {
              console.log(errorFields)
              props.onError && props.onError(errorFields)
            }}
            onFinish={values => {
              console.log(values)
              setSubmiting(true)
              props.onSubmit &&
                props
                  .onSubmit(values)
                  .then(() => {
                    message.success('保存成功')
                    refresh(page)
                    setSubmiting(false)
                    setModalMode(-1)
                  })
                  .catch(err => {
                    setSubmiting(false)
                    message.error(err.msg || '出现错误，请重试')
                  })
            }}
          >
            {props.formItems && props.formItems}
            <Form.Item wrapperCol={{ offset: 3 }}>
              <Space>
                <Button type="primary" htmlType="submit" disabled={!props.onSubmit}>
                  保存
                </Button>
                <Button
                  onClick={() => {
                    setModalMode(-1)
                  }}
                >
                  取消
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  )
}

const ForwardCRUDTable = forwardRef(CRUDTable) as <T extends AnyObject = any>(
  props: CRUDTableProps<T> & {
    ref?: Ref<CRUDTableInstance>
  }
) => ReactElement

export default ForwardCRUDTable
