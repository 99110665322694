import React from 'react'
import * as ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { routes } from './route'

// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/locale/zh_CN'
import 'antd/dist/reset.css'
import './index.scss'

import { isProduction } from './utils/constant'
import { isLarge } from './utils/rem'
import { Provider } from 'react-redux'
import store from './store'

import Loading from './components/loading'
import Loader from './components/loader'
import Catcher from './components/catcher'

dayjs.locale('zh-cn')
const Home = React.lazy(() => import('./pages/home'))
const Login = React.lazy(() => import('./pages/login'))
const Demo = Loader(() => import('./pages/demo'))
const Error = Loader(() => import('./pages/error'))

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <React.Suspense fallback={<Loading />}>
      <Catcher>
        <Provider store={store}>
          <ConfigProvider
            locale={zhCN}
            theme={{
              token: {
                colorPrimary: '#0078d4'
              }
            }}
            componentSize={isLarge() ? 'large' : 'middle'}
          >
            <Routes>
              <Route path="/" element={<Home />}>
                <Route path="" element={<Navigate replace to={routes[0].path} />} />
                {routes.map((route, index) => {
                  return <Route key={index} path={route.path} element={<route.element />} />
                })}
              </Route>
              <Route path="/demo" element={isProduction ? <Navigate replace to="/error" /> : <Demo />} />
              <Route path="/login" element={<Login />} />
              <Route path="/error" element={<Error />} />
              <Route path="*" element={<Navigate replace to="/error" />} />
            </Routes>
          </ConfigProvider>
        </Provider>
      </Catcher>
    </React.Suspense>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
