import { CRUDFactory, http } from '../utils/http'

const service = new CRUDFactory('/category')

export function fetchAllCategory(pId: number, adminId?: number) {
  return http.get<{
    list: NeedCode.Entity.Category[]
  }>(`/category/all?pId=${pId}${adminId ? `&adminId=${adminId}` : ''}`)
}

export default service
