import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit'

import { cacheKeys } from '../utils/constant'
import { get, remove } from '../utils/storage'

export interface IAppState {
  admin?: NeedCode.Entity.Admin
  routes: any[]
}

export const appSlice = createSlice<IAppState, SliceCaseReducers<IAppState>>({
  name: 'app',
  initialState: {
    admin: _getCache(),
    routes: []
  },
  reducers: {
    login: (state, action) => {
      // set(cacheKeys.admin, action.payload, 12 * 60 * 60 * 1000)
      // set(cacheKeys.token, action.payload.key, 12 * 60 * 60 * 1000)
      state.admin = action.payload
    },
    logout: state => {
      remove(cacheKeys.admin)
      remove(cacheKeys.token)
      state.admin = undefined
    }
  }
})

function _getCache() {
  const c = get<NeedCode.Entity.Admin>(cacheKeys.admin)
  if (c) {
    return c
  }
  remove(cacheKeys.admin)
  return undefined
}
