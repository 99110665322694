import { Form, Input } from 'antd'

import CRUDTable from '../../components/crud-table'
import FormItemAvatar from '../../components/form/form-item-avatar'
import adminService from '../../api/admin'

const defaultValues = {
  name: '',
  avatar: '',
  email: '',
  appid: '',
  password: ''
}

export default function Admin() {
  return (
    <div>
      <CRUDTable<NeedCode.Entity.Admin>
        onLoad={(page, pageSize) => {
          return adminService.list(`page=${page}&pageSize=${pageSize}`).then(res => {
            return {
              ...res.data
            }
          })
        }}
        onRemove={item => {
          return adminService.remove(item.id)
        }}
        onSubmit={form => {
          if (form.id) {
            const { id, ...updateForm } = form
            return adminService.update(id, updateForm)
          } else {
            return adminService.create(form)
          }
        }}
        onBeforeEdit={item => {
          return Promise.resolve({
            ...item,
            password: ''
          })
        }}
        columns={[
          {
            title: 'Id',
            dataIndex: 'id'
          },
          {
            title: '应用ID',
            dataIndex: 'appid'
          },
          {
            title: '名称',
            dataIndex: 'name'
          },
          {
            title: '邮箱',
            dataIndex: 'email'
          }
        ]}
        formValue={defaultValues}
        formItems={
          <>
            <FormItemAvatar></FormItemAvatar>
            <Form.Item name="appid" label="应用ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="name" label="名称" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="邮箱" rules={[{ required: true, type: 'email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="password" label="新密码" rules={[{ required: true, min: 6 }]}>
              <Input type="password" />
            </Form.Item>
            <Form.Item name="id" hidden noStyle></Form.Item>
          </>
        }
      ></CRUDTable>
    </div>
  )
}
