export function format(format: string, time?: number) {
  const now = time ? new Date(time) : new Date()
  const map: Record<string, string | number> = {
    y: now.getFullYear(),
    M: now.getMonth() + 1,
    d: now.getDate(),
    h: now.getHours() > 9 ? now.getHours() : `0${now.getHours()}`,
    m: now.getMinutes() > 9 ? now.getMinutes() : `0${now.getMinutes()}`,
    s: now.getSeconds() > 9 ? now.getSeconds() : `0${now.getSeconds()}`
  }
  let res = []
  for (let i = 0; i < format.length; i++) {
    if (map[format.charAt(i)]) {
      res.push(map[format.charAt(i)])
    } else {
      res.push(format.charAt(i))
    }
  }
  return res.join('')
}
