import { Avatar, Button, Form, Space, Typography } from 'antd'
import Uploader from '../uploader'

export default function FormItemAvatar(props: { field?: string }) {
  return (
    <>
      <Form.Item label="头像" rules={[{ required: true }]} shouldUpdate>
        {form => {
          return (
            <Uploader
              ext={['png', 'jpeg', 'jpg']}
              onSuccess={urls => {
                form.setFieldValue(props.field || 'avatar', urls[0].url)
              }}
            >
              <Space direction="vertical">
                <Avatar size={64} src={form.getFieldValue(props.field || 'avatar')} />
                <Space>
                  <Button>上传图片</Button>
                  {form.getFieldValue(props.field || 'avatar') ? (
                    ''
                  ) : (
                    <Typography.Text type="danger">请上传图片</Typography.Text>
                  )}
                </Space>
              </Space>
            </Uploader>
          )
        }}
      </Form.Item>
      <Form.Item name="avatar" rules={[{ required: true }]} hidden noStyle></Form.Item>
    </>
  )
}
