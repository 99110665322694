export const isProduction = !/localhost|simsir-lin|192/.test(window.location.href)
// export const host = isProduction ? 'https://needcodestudio.com/api' : '/api'
export const host = isProduction ? 'https://needcodestudio.com/api' : 'http://127.0.0.1:7001/api'

export const cacheKeys = {
  token: 'TOKEN_CACHE_20231109',
  admin: 'ADMIN_CACHE_20231109'
}

export const appid = {
  super: 'needcode',
  srv: 'wxcfc482e2683d4e3d'
}
