import { ReactNode, useState, useCallback } from 'react'
import { Spin, Upload, message } from 'antd'

import { oss } from '../../api/oss'
import { upload } from '../../utils/uploader'

const extToAccept = (ext: string[]): string => {
  const acceptMap: Record<string, string> = {
    jpg: 'image',
    png: 'image',
    jpeg: 'image',
    gif: 'image'
  }

  return ext
    .filter(type => acceptMap[type])
    .map(type => `${acceptMap[type]}/${type}`)
    .join(', ')
}

interface UploaderUpload {
  url: string
  height?: number
  width?: number
}

interface UploaderProps {
  title?: string // 默认值：文件。上传文件的代词，在出现提示时去指明更详细的信息
  accept?: string // 接受上传的类型
  ext?: string[] // 指定后戳，上传前会校验格式
  limit?: number // 单次上传文件个数
  drag?: boolean // 是否支持拖拽上传
  size?: number // 图片大小,默认2mb
  meta?: string
  children?: ReactNode
  autoShowError?: boolean // 自动弹出上传失败提示
  autoShowErrorDuration?: number // 上传失败提示时间
  debug?: boolean // 设置调试文件URL，不走真实上次逻辑，固定返回设置的文件URL
  onSuccess?: (res: UploaderUpload[]) => void // 上传成功回调
  onError?: (message: string[]) => void // 上传失败回调
  beforeUpload?: (file: File) => Promise<void> // 上传前执行
}

let __UploaderFileList: any[] = []
let __UploaderResponseList: any[] = []

export default function Uploader(props: UploaderProps) {
  const { size = 1, title = '文件', autoShowError = true, autoShowErrorDuration = 3 } = props
  const [loading, setLoading] = useState(false)

  const finish = useCallback(() => {
    const res: UploaderUpload[] = []
    __UploaderResponseList.forEach(item => {
      if (!item.msg) {
        res.push(item)
      }
    })
    __UploaderResponseList = []
    __UploaderFileList = []
    setLoading(false)
    if (res.length <= 0) {
      const msgs = __UploaderResponseList.filter(item => item.msg).map(item => item.msg)
      if (autoShowError) {
        msgs.forEach(item => {
          message.error(item, autoShowErrorDuration)
        })
      }
      props.onError && props.onError(msgs)
      return
    }
    message.success(`成功上传${res.length}个${title}`, autoShowErrorDuration)
    props.onSuccess && props.onSuccess(res)
  }, [autoShowError, autoShowErrorDuration, props, title])

  return (
    <Spin spinning={loading}>
      <Upload
        maxCount={props.limit || 1}
        multiple={(props.limit && props.limit > 1) as boolean}
        accept={props.ext ? extToAccept(props.ext) : props.accept}
        beforeUpload={(file: File, fileList) => {
          if (__UploaderFileList.length <= 0) {
            __UploaderFileList = fileList
            __UploaderResponseList = []
          }
          return new Promise((resolve, reject) => {
            if (props.debug) {
              resolve(true)
              return
            }
            const ext = (file as AnyObject).name.substr((file as AnyObject).name.lastIndexOf('.') + 1)
            if (props.ext && props.ext.indexOf(ext) < 0) {
              message.error(`支持${props.ext.join('，')}格式的${title}`)
              reject(false)
              return
            }
            if (file.size > size * 1024 * 1024) {
              message.error(`请选择小于${size}MB的${title}`)
              reject(false)
              return
            }
            if (props.beforeUpload) {
              props
                .beforeUpload(file)
                .then(() => {
                  resolve(true)
                })
                .catch(err => {
                  console.log(err)
                  reject(false)
                })
              return
            }
            resolve(true)
          })
        }}
        showUploadList={false}
        fileList={[]}
        customRequest={option => {
          if (props.debug) {
            props.onSuccess &&
              props.onSuccess([
                {
                  url: 'https://needcode.oss-cn-shenzhen.aliyuncs.com/common/needcode.png'
                }
              ])
            return
          }
          // console.log(option)
          if (option.file) {
            setLoading(true)
            const formData = new FormData()
            const ext = (option.file as any).name
              .substring((option.file as any).name.lastIndexOf('.') + 1)
              .toLowerCase()
            const filename = `${new Date().getTime()}.${ext}`
            oss({
              filename
            })
              .then(res => {
                formData.append('key', res.key)
                formData.append('policy', res.policy)
                formData.append('OSSAccessKeyId', res.accessId)
                formData.append('signature', res.signature)
                formData.append('success_action_status', '200')
                formData.append('Cache-Control', 'public, max-age=31536000')
                if (props.meta) {
                  formData.append('x-oss-meta-ht', props.meta)
                }
                formData.append('file', option.file)
                upload({
                  formData: formData,
                  action: res.host,
                  onSuccess: () => {
                    __UploaderResponseList.push({
                      url: `${res.host}/${res.key}`
                    })
                    if (__UploaderFileList.length === __UploaderResponseList.length) {
                      finish()
                    }
                  },
                  onError: err => {
                    __UploaderResponseList.push(err)
                    if (__UploaderFileList.length === __UploaderResponseList.length) {
                      finish()
                    }
                  }
                })
              })
              .catch(err => {
                __UploaderResponseList.push(err)
                if (__UploaderFileList.length === __UploaderResponseList.length) {
                  finish()
                }
              })
          }
        }}
      >
        {props.children}
      </Upload>
    </Spin>
  )
}
