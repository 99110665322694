import { Button, Result } from 'antd'
import { Component, ErrorInfo, ReactNode } from 'react'

class Catcher extends Component<
  {
    children?: ReactNode
  },
  {
    hasError: boolean
  }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.warn(error)
    console.warn(errorInfo)
    this.setState({ hasError: true })
  }

  render() {
    return this.state.hasError ? (
      <Result
        status="error"
        title="出现错误"
        extra={[
          <Button type="primary" href="/">
            返回主页
          </Button>
        ]}
      />
    ) : (
      this.props.children
    )
  }
}

export default Catcher
