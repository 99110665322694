import { CRUDFactory, http } from '../utils/http'

const service = new CRUDFactory('/admin')

export default service

export function fetchAll() {
  return http.get<{
    list: NeedCode.Entity.Admin[]
  }>('/admin/all')
}
