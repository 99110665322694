import categoryService, { fetchAllCategory } from '../../api/category'
import { fetchAll } from '../../api/admin'

import { appid } from '../../utils/constant'
import { Space, Select, Button, Input, Tree, message, Spin, Empty, Typography, Modal, Popconfirm } from 'antd'

import { useSelector } from 'react-redux'
import { IState } from '../../store'
import { useCallback, useEffect, useState } from 'react'
import './index.scss'

interface CategoryNode {
  title: string
  key: string
  isLeaf?: boolean
  children?: CategoryNode[]
}

const updateTreeData = (list: CategoryNode[], key: React.Key, children: CategoryNode[]): CategoryNode[] =>
  list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children
      }
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children)
      }
    }
    return node
  })

export default function Category() {
  const [admins, setAdmins] = useState<NeedCode.Entity.Admin[]>([])
  const [categories, setCategories] = useState<CategoryNode[]>()
  const [params, setParams] = useState<{
    adminId: number
  }>({
    adminId: 0
  })
  const admin = useSelector<IState, NeedCode.Entity.Admin | undefined>(state => state.app.admin)

  const [inputDialog, setInputDialog] = useState(false)
  const [saveing, setSaveing] = useState(false)
  const [inputDialogForm, setInputDialogForm] = useState({
    text: '',
    title: '',
    id: 0,
    pId: 0
  })

  useEffect(() => {
    if (admin?.appid === appid.super) {
      fetchAll().then(res => {
        setAdmins(res.data.list.filter(item => item.appid !== appid.super))
      })
    }
  }, [admin])

  useEffect(() => {
    fetchAllCategory(0, params.adminId).then(res => {
      setCategories(
        (res.data.list || []).map(item => {
          return {
            title: item.name,
            key: `${item.id}`,
            isLeaf: item.id === 0
          }
        })
      )
    })
  }, [params])

  const refresh = useCallback(() => {
    setCategories([])
    return fetchAllCategory(0, params.adminId).then(res => {
      setCategories(
        (res.data.list || []).map(item => {
          return {
            title: item.name,
            key: `${item.id}`,
            isLeaf: item.id === 0
          }
        })
      )
    })
  }, [params.adminId])

  return (
    <div>
      <div className="category-header">
        <Space className="pl-10">
          <Button
            type="primary"
            onClick={() => {
              setInputDialogForm({
                text: '',
                id: 0,
                pId: 0,
                title: `新增分类`
              })
              setInputDialog(true)
            }}
          >
            新增分类
          </Button>
          {admin?.appid === appid.super ? (
            <Select
              style={{ width: 200 }}
              className="ml-24"
              placeholder="选择应用"
              allowClear
              options={admins.map(admin => {
                return { value: admin.id, label: admin.name }
              })}
              onChange={value => {
                setParams(val => {
                  val.adminId = value
                  return {
                    ...val
                  }
                })
              }}
              onClear={() => {
                setParams(val => {
                  val.adminId = 0
                  return {
                    ...val
                  }
                })
              }}
            />
          ) : (
            ''
          )}
        </Space>
      </div>
      <div className="mt-20">
        <Spin tip="加载中" spinning={!categories}>
          {categories ? (
            categories.length ? (
              <Tree
                loadData={({ key, children }) => {
                  if (children) {
                    return Promise.resolve()
                  }
                  const keySplit = key.split('-')
                  return fetchAllCategory(Number(keySplit[keySplit.length - 1]), params.adminId).then(res => {
                    setCategories(val => {
                      return updateTreeData(
                        val || [],
                        key,
                        (res.data.list || []).map(newitem => {
                          return {
                            title: newitem.name,
                            key: `${key}-${newitem.id}`,
                            isLeaf: false
                          }
                        })
                      )
                    })
                  })
                }}
                showLine={true}
                selectable={false}
                treeData={categories}
                blockNode
                titleRender={nodeData => {
                  return (
                    <Space size="large">
                      <Typography.Text>{nodeData.title}</Typography.Text>
                      <Typography.Link
                        onClick={() => {
                          setInputDialogForm({
                            text: nodeData.title,
                            id: Number(nodeData.key),
                            pId: 0,
                            title: `修改分类`
                          })
                          setInputDialog(true)
                        }}
                      >
                        修改分类
                      </Typography.Link>
                      <Popconfirm
                        title="删除确认"
                        description={`是否确定删除${nodeData.title}（包含层级下所有分类）？`}
                        onConfirm={() => {
                          setSaveing(true)
                          return categoryService
                            .remove(Number(nodeData.key))
                            .then(() => {
                              return refresh()
                            })
                            .then(() => {
                              message.success('删除成功')
                              setSaveing(false)
                            })
                            .catch(() => {
                              setSaveing(false)
                            })
                        }}
                        okButtonProps={{
                          disabled: saveing,
                          loading: saveing
                        }}
                        cancelButtonProps={{
                          disabled: saveing
                        }}
                        okText="确定"
                        cancelText="取消"
                      >
                        <Typography.Link type="danger" onClick={() => {}}>
                          删除分类
                        </Typography.Link>
                      </Popconfirm>
                      <span></span>
                      <span></span>
                      <Typography.Link
                        type="success"
                        onClick={() => {
                          const keySplit = nodeData.key.split('-')
                          setInputDialogForm({
                            text: '',
                            id: 0,
                            pId: Number(keySplit[keySplit.length - 1]),
                            title: `新增${nodeData.title}下级分类`
                          })
                          setInputDialog(true)
                        }}
                      >
                        新增下级分类
                      </Typography.Link>
                    </Space>
                  )
                }}
              />
            ) : (
              <Empty description="暂无分类" />
            )
          ) : (
            <div className="height-80"></div>
          )}
        </Spin>
      </div>
      <Modal
        open={inputDialog}
        title={inputDialogForm.title}
        destroyOnClose
        okButtonProps={{
          disabled: saveing || !inputDialogForm.text,
          loading: saveing
        }}
        okText="保存"
        onCancel={() => setInputDialog(false)}
        onOk={() => {
          setSaveing(true)
          const promise =
            inputDialogForm.id > 0
              ? categoryService.update(inputDialogForm.id, {
                  name: inputDialogForm.text
                })
              : categoryService.create({
                  pId: inputDialogForm.pId,
                  name: inputDialogForm.text
                })
          promise
            .then(() => {
              return refresh()
            })
            .then(() => {
              message.success('保存成功')
              setSaveing(false)
              setInputDialog(false)
            })
            .catch(err => {
              setSaveing(false)
              message.error(err.msg || '出现错误，请重试')
            })
        }}
        centered
      >
        <div className="pt-14 pb-14">
          <Input
            placeholder="输入分类名称"
            defaultValue={inputDialogForm.text}
            disabled={saveing}
            maxLength={40}
            onChange={e => {
              setInputDialogForm(val => {
                val.text = e.target.value
                return {
                  ...val
                }
              })
            }}
          />
        </div>
      </Modal>
    </div>
  )
}
