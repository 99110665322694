import { http } from '../utils/http'

export async function oss(params: { filename: string }): Promise<{
  accessId: string
  expireTime: number
  host: string
  key: string
  policy: string
  signature: string
}> {
  return http.post('/oss', params).then(res => res.data)
}
