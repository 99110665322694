import { lazy } from 'react'
import { appid } from './utils/constant'
import admin from './pages/admin'

import config from './pages-common/config'
import user from './pages-common/user'
import category from './pages-common/category'

export interface IRoute {
  title: string
  desc: string
  path: string
  element: any
  appid?: string
}

export const appRoutes: IRoute[] = [
  {
    title: '系统配置',
    desc: '',
    path: 'app/config',
    element: config
  },
  {
    title: '管理员配置',
    desc: '可对系统进行新增、编辑、删除',
    path: 'app/admin',
    element: admin,
    appid: appid.super
  }
]

export const userRoutes: IRoute[] = [
  {
    title: '用户列表',
    desc: '系统目前已注册用户',
    path: 'users',
    element: user
  }
]

const products = lazy(() => import('./pages-store/product'))
const delivery = lazy(() => import('./pages-store/delivery'))

export const storeRoutes: IRoute[] = [
  {
    title: '商品列表',
    desc: '设置小程序商场草稿数据',
    path: 'store/products',
    element: products
  },
  {
    title: '配送设置',
    desc: '设置线上商品配送方式与运费',
    path: 'store/delivery',
    element: delivery
  }
]

// srv小程序
const artist = lazy(() => import('./pages-srv/artist'))
const show = lazy(() => import('./pages-srv/show'))
const works = lazy(() => import('./pages-srv/works'))

export const srvRoutes: IRoute[] = [
  {
    title: '艺术家列表',
    desc: '可对艺术家进行新增、编辑、删除',
    path: 'srv/artist',
    element: artist
  },
  {
    title: '展览列表',
    desc: '可对展览进行新增、编辑、删除',
    path: 'srv/show',
    element: show
  },
  {
    title: '作品列表',
    desc: '可对作品进行新增、编辑、删除',
    path: 'srv/works',
    element: works
  }
]

export const miniprogramRoutes: IRoute[] = [
  {
    title: '分类管理',
    desc: '',
    path: 'category',
    element: category
  },
  ...srvRoutes.map(item => {
    return {
      ...item,
      appid: appid.srv
    }
  })
]

export const routes: IRoute[] = [...userRoutes, ...appRoutes, ...storeRoutes, ...miniprogramRoutes]
