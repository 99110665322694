export function set(key: string, data: any, expire: number) {
  if (!key) return
  if (typeof data === 'object') {
    data = JSON.stringify(data)
  }
  let time = 0
  if (expire && /\d+/.test(expire + '')) {
    let now = new Date().getTime()
    time = now + expire
  }

  localStorage.setItem(
    key,
    JSON.stringify({
      data,
      expire: time
    })
  )
}

export function get<T>(key: string): T | null {
  if (!key) return null
  let data = null
  let storage = localStorage.getItem(key)
  if (storage) {
    try {
      let res = JSON.parse(storage)
      if (_checkExpire(res.expire)) {
        if (res.data.indexOf('{') === 0 || res.data.indexOf('[') === 0) {
          data = JSON.parse(res.data)
        } else {
          data = res.data
        }
      } else {
        localStorage.removeItem(key)
      }
    } catch (err) {
      data = null
    }
  }
  return data
}

function _checkExpire(expire: number) {
  if (expire <= 0) {
    return true
  }
  return Number(expire) - new Date().getTime() > 1000
}

export function remove(key: string) {
  if (!key) return
  localStorage.removeItem(key)
}
