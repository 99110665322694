import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function Loading() {
  return (
    <div style={{ width: '100%', height: '100%' }} className="flex-center">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  )
}
