import { Form, Input, InputNumber } from 'antd'

import { appid } from '../../utils/constant'
import CRUDTable from '../../components/crud-table'
import configService from '../../api/config'

import SrvHome from './srv-home'
import SrvCode from './srv-code'

import { useSelector } from 'react-redux'
import { IState } from '../../store'

const defaultValues = {
  adminId: 0,
  name: '',
  data: ''
}

export default function Config() {
  const admin = useSelector<IState, NeedCode.Entity.Admin | undefined>(state => state.app.admin)
  return (
    <div>
      <CRUDTable<NeedCode.Entity.Config>
        canCreate={admin?.appid === appid.super}
        canRemove={admin?.appid === appid.super}
        onLoad={(page, pageSize) => {
          return configService.list(`page=${page}&pageSize=${pageSize}`).then(res => {
            return {
              ...res.data
            }
          })
        }}
        onRemove={item => {
          return configService.remove(item.id)
        }}
        onSubmit={form => {
          if (form.id) {
            const { id, ...updateForm } = form
            return configService.update(id, updateForm)
          } else {
            return configService.create(form)
          }
        }}
        onBeforeEdit={item => {
          return Promise.resolve({
            ...item,
            password: ''
          })
        }}
        columns={
          admin?.appid === appid.super
            ? [
                {
                  title: 'Id',
                  dataIndex: 'id'
                },
                {
                  title: 'AdminId',
                  dataIndex: 'adminId'
                },
                {
                  title: '名称',
                  dataIndex: 'name'
                },
                {
                  title: '键名',
                  dataIndex: 'akey'
                }
              ]
            : [
                {
                  title: '名称',
                  dataIndex: 'name'
                }
              ]
        }
        formValue={defaultValues}
        formItems={
          <>
            <Form.Item noStyle shouldUpdate>
              {form => {
                const id = form.getFieldValue('id')
                const akey = form.getFieldValue('akey')
                const data = form.getFieldValue('data')
                if (id) {
                  switch (akey) {
                    case 'srv-home':
                      return (
                        <>
                          <div className="pb-40">
                            <SrvHome
                              data={data}
                              onChange={data => {
                                form.setFieldValue('data', JSON.stringify(data))
                              }}
                            ></SrvHome>
                          </div>
                          <Form.Item name="akey" hidden noStyle></Form.Item>
                          <Form.Item name="name" hidden noStyle></Form.Item>
                          <Form.Item name="adminId" hidden noStyle></Form.Item>
                        </>
                      )
                    case 'srv-code':
                      return (
                        <>
                          <div className="pb-40">
                            <SrvCode
                              data={data}
                              onChange={data => {
                                form.setFieldValue('data', JSON.stringify(data))
                              }}
                            ></SrvCode>
                          </div>
                          <Form.Item name="akey" hidden noStyle></Form.Item>
                          <Form.Item name="name" hidden noStyle></Form.Item>
                          <Form.Item name="adminId" hidden noStyle></Form.Item>
                        </>
                      )
                  }
                } else {
                  return (
                    <>
                      <Form.Item name="adminId" label="adminId" rules={[{ required: true }]}>
                        <InputNumber style={{ width: 300 }} />
                      </Form.Item>
                      <Form.Item name="name" label="名称" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name="akey" label="Key" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    </>
                  )
                }
              }}
            </Form.Item>
            <Form.Item name="data" hidden noStyle></Form.Item>
            <Form.Item name="id" hidden noStyle></Form.Item>
          </>
        }
      ></CRUDTable>
    </div>
  )
}
