import { Button, Input, Tabs, Image } from 'antd'
import { useState } from 'react'

import Uploader from '../../components/uploader'

type SrvHomeConfig = {
  works: string
  show: string
  about: string
  image: string
}

export default function SrvHome(props: { data: string; onChange: (data: SrvHomeConfig) => void }) {
  const [tab, setTab] = useState<string>('1')
  const [config, setConfig] = useState<SrvHomeConfig>(
    props.data
      ? JSON.parse(props.data)
      : {
          works: '',
          show: '',
          about: '',
          image: ''
        }
  )

  return (
    <Tabs
      defaultActiveKey={tab}
      onChange={val => {
        setTab(val)
      }}
      tabPosition="left"
      items={[
        {
          key: '1',
          label: '首页作品',
          children: (
            <div className="pb-40">
              <p>作品ID（使用｜分割）</p>
              <Input
                defaultValue={config.works}
                placeholder="输入格式：1|2|3"
                onChange={e => {
                  const value = e.target.value
                  setConfig(val => {
                    val.works = value
                    return {
                      ...val
                    }
                  })
                  setTimeout(() => {
                    props.onChange(config)
                  })
                }}
              />
            </div>
          )
        },
        {
          key: '2',
          label: '首页展览',
          children: (
            <div className="pb-40">
              <p>展览ID（使用｜分割）</p>
              <Input
                defaultValue={config.show}
                placeholder="输入格式：1|2|3"
                onChange={e => {
                  const value = e.target.value
                  setConfig(val => {
                    val.show = value
                    return {
                      ...val
                    }
                  })
                  setTimeout(() => {
                    props.onChange(config)
                  })
                }}
              />
            </div>
          )
        },
        {
          key: '3',
          label: '关于我们',
          children: (
            <div className="pb-40">
              <div className="flex">
                <div style={{ width: '50%' }}>
                  <p>关于我们</p>
                  <Input.TextArea
                    defaultValue={config.about}
                    onChange={e => {
                      const value = e.target.value
                      setConfig(val => {
                        val.about = value
                        return {
                          ...val
                        }
                      })
                      setTimeout(() => {
                        props.onChange(config)
                      })
                    }}
                    rows={10}
                    placeholder="请输入关于我们"
                  />
                </div>
                <div className="flex-column ml-20" style={{ width: '40%' }}>
                  <p>背景图</p>
                  {config.image ? (
                    <div className="pb-20">
                      <Image width="100%" src={config.image}></Image>
                    </div>
                  ) : (
                    ''
                  )}
                  <Uploader
                    limit={1}
                    ext={['png', 'jpeg', 'jpg']}
                    onSuccess={urls => {
                      setConfig(val => {
                        val.image = urls[0].url
                        return {
                          ...val
                        }
                      })
                      setTimeout(() => {
                        props.onChange(config)
                      })
                    }}
                  >
                    <Button>上传图片</Button>
                  </Uploader>
                  {config.image ? (
                    <div className="mt-10">
                      <Button
                        danger
                        onClick={() => {
                          setConfig(val => {
                            val.image = ''
                            return {
                              ...val
                            }
                          })
                          setTimeout(() => {
                            props.onChange(config)
                          })
                        }}
                      >
                        移除图片
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )
        }
      ]}
    ></Tabs>
  )
}
