import CRUDTable, { CRUDTableInstance } from '../../components/crud-table'
import userService from '../../api/user'
import { fetchAll } from '../../api/admin'

import { appid } from '../../utils/constant'
import { format } from '../../utils/date'
import { Avatar, Space, Select } from 'antd'

import { useSelector } from 'react-redux'
import { IState } from '../../store'
import { useEffect, useRef, useState } from 'react'

export default function User() {
  const tableRef = useRef<CRUDTableInstance>(null)
  const [admins, setAdmins] = useState<NeedCode.Entity.Admin[]>([])
  const [params, setParams] = useState<{
    adminId: string
  }>({
    adminId: ''
  })
  const admin = useSelector<IState, NeedCode.Entity.Admin | undefined>(state => state.app.admin)

  useEffect(() => {
    if (admin?.appid === appid.super) {
      fetchAll().then(res => {
        setAdmins(res.data.list.filter(item => item.appid !== appid.super))
      })
    }
  }, [admin])

  return (
    <div>
      <CRUDTable<NeedCode.Entity.User>
        ref={tableRef}
        params={params}
        onLoad={(page, pageSize, params) => {
          let query = `page=${page}&pageSize=${pageSize}`
          if (params.adminId) {
            query += `&adminId=${params.adminId}`
          }
          return userService.list(query).then(res => {
            return {
              ...res.data
            }
          })
        }}
        header={
          admin?.appid === appid.super ? (
            <Space className="pl-10">
              <Select
                style={{ width: 200 }}
                placeholder="选择应用用户"
                allowClear
                options={admins.map(artist => {
                  return { value: artist.id, label: artist.name }
                })}
                onChange={value => {
                  setParams(val => {
                    val.adminId = value
                    return {
                      ...val
                    }
                  })
                }}
                onClear={() => {
                  setParams(val => {
                    val.adminId = ''
                    return {
                      ...val
                    }
                  })
                }}
              />
            </Space>
          ) : (
            ''
          )
        }
        columns={[
          {
            title: 'Id',
            dataIndex: 'id'
          },
          {
            title: '昵称头像',
            dataIndex: 'nickname',
            render: (nickname, record) => {
              return (
                <Space>
                  <Avatar size={48} src={record.avatar} />
                  {nickname}
                </Space>
              )
            }
          },
          {
            title: '性别',
            dataIndex: 'gender',
            render: gender => {
              return <>{gender === 0 ? '女' : gender === 1 ? '男' : '未知'}</>
            }
          },
          {
            title: '来自',
            dataIndex: 'country',
            render: (country, record) => {
              return (
                <>
                  {country}-{record.province}-{record.city}
                </>
              )
            }
          },
          {
            title: '注册时间',
            dataIndex: 'time',
            render: time => {
              return <>{format('y-M-d h:m', time * 1000)}</>
            }
          }
        ]}
      ></CRUDTable>
    </div>
  )
}
