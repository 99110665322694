import { Modal } from 'antd'
import { host, cacheKeys } from './constant'
import { get, remove } from './storage'

import Axios from 'axios'

const TOKEN_HEADER_KEY = 'authorization'
const ERROR_STATUS_CODE = [500, 404, 422, 401, 403]
const ERROR_MESSAGE = ['服务器出错', '暂无接口', '参数异常', '登录已失效', '无权限访问']
const instance = Axios.create({
  baseURL: host
})
instance.defaults.withCredentials = true
instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截器
instance.interceptors.request.use(
  config => {
    config.headers[TOKEN_HEADER_KEY] = get(cacheKeys.token) || ''
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  res => {
    if (res.status === 200 && res.data.status === 200 && res.data.msg === 'ok') {
      return Promise.resolve(res.data)
    }
    if (res.status === 200 && res.data.status === 401) {
      remove(cacheKeys.admin)
      remove(cacheKeys.token)
      Modal.confirm({
        title: '提示',
        content: '登录已失效，请重新登录',
        okText: '重新登录',
        cancelText: '',
        afterClose() {
          window.location.href = '/'
        },
      })
    }
    return Promise.reject({
      status: res.status,
      msg: res.data.msg
    })
  },
  error => {
    let msg = JSON.stringify(error)
    if (ERROR_STATUS_CODE.indexOf(error.request.status) >= 0) {
      msg = ERROR_MESSAGE[ERROR_STATUS_CODE.indexOf(error.request.status)]
    }
    if (error.request.response) {
      let json = JSON.parse(error.request.response)
      if (json.msg) {
        msg = json.msg
      }
    }
    return Promise.reject({
      code: error.request.status,
      msg: msg
    })
  }
)

export const http = instance

export class CRUDFactory {
  api: string

  constructor(api: string) {
    this.api = api
  }

  list(query: string) {
    return instance.get(`${this.api}?` + query)
  }
  get(id: number) {
    return instance.get(`${this.api}/${id}`)
  }
  remove(id: number) {
    return instance.delete(`${this.api}/${id}`)
  }
  create<T = any>(params: T) {
    return instance.post(this.api, params)
  }
  update<T = any>(id: number, params: Tool.Partial<T>) {
    return instance.put(`${this.api}/${id}`, params)
  }
}
