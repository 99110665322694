import { Button, Image } from 'antd'
import { useState } from 'react'

import Uploader from '../../components/uploader'

type SrvCodeConfig = {
  url: string
}

export default function SrvCode(props: { data: string; onChange: (data: SrvCodeConfig) => void }) {
  const [config, setConfig] = useState<SrvCodeConfig>(
    props.data
      ? JSON.parse(props.data)
      : {
          url: ''
        }
  )

  return (
    <div className="flex-column ml-20" style={{ width: '30%' }}>
      {config.url ? (
        <div className="pb-20">
          <Image width="100%" src={config.url}></Image>
        </div>
      ) : (
        ''
      )}
      <Uploader
        limit={1}
        ext={['png', 'jpeg', 'jpg']}
        onSuccess={urls => {
          setConfig(val => {
            val.url = urls[0].url
            return {
              ...val
            }
          })
          setTimeout(() => {
            props.onChange(config)
          })
        }}
      >
        <Button>上传二维码</Button>
      </Uploader>
    </div>
  )
}
